import React from 'react';
import PropTypes from 'prop-types';
import ClientPortalProvider from '@phillipsauctionhouse/client-portal/exports/ClientSideClientPortalProvider';
import getAuth from '../Auth/getAuth';

const isCSR = typeof window !== 'undefined';
const {
  apiHost,
  gmapsApiKey,
  adyenEnvironment,
  adyenClientKey,
  instrumentationKey,
  phillipsHost,
  auctionMobilityHost
} = isCSR ? window.clientPortalConfig : {};
const globalCdnUrl = isCSR ? window.globalCdnUrl : "";
const ConfiguredClientPortalProvider = ({ children }) => (
  <ClientPortalProvider
    routeConfig={{ basename: '/account' }}
    phillipsHost={phillipsHost}
    phillipsCdnUrl={globalCdnUrl}
    apiHost={apiHost}
    gmapsApiKey={gmapsApiKey}
    adyenEnvironment={adyenEnvironment}
    adyenClientKey={adyenClientKey}
    instrumentationKey={instrumentationKey}
    locale="en-US"
    perpetualStoreUrl="/store/perpetual"
    bidsUrl={`${auctionMobilityHost}/bid-sheet/upcoming`}
    onAnalyticsEvent={({ name, ...params }) => {
      if (typeof gtag == 'function') {
        gtag('event', `client_portal_${name}`, params);
      }

      if (typeof fbq !== 'undefined') {
        fbq('trackCustom', `client_portal_${name}`, params);
      }
    }
    }
    onNotFoundError={() => window.location.replace('/NotFound')}
    authPromise={getAuth()}
  >
    {children}
  </ClientPortalProvider>
);
ConfiguredClientPortalProvider.propTypes = {
  children: PropTypes.node.isRequired
};
export default ConfiguredClientPortalProvider;