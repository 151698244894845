/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import LazyPhillipsPublicLotTileControl from '@phillipsauctionhouse/client-portal/exports/LazyPhillipsPublicLotTileControl';
import sendAnalytics from '../../utils/sendAnalytics';
import LotDescription from './LotDescription';
import NoLot from './NoLot';
import PhillipsLotImage from './PhillipsLotImage';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';
import { defaultLotProps, lotPropTypes } from '../PropTypes/proptypes';

const PhillipsLot = (props) => {
  const user = useSelector(state => state.user);
  const [isHovering, setIsHovering] = useState(false);
  const [hideTileControl, setHideTileControl] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { auctionMobilityLotRowId, className, disableFetchRowId, fetchedLotRowIds, fetchLotRowIds, fetchLotWidgetData, getI18nLabel, hammerPlusBP, language, isNoLot, saleTypeId, websiteUrl, widgetEnabled } = props;
  const showBidButton = widgetEnabled
    ? auctionMobilityLotRowId !== '0'
    : false;
  
    useEffect(() => {
      const mql = window.matchMedia("screen and (min-width: 1112px)");
  
      if (mql.matches) {
        setHideTileControl(true);
      } else {
        setHideTileControl(false);
      }
  
      const handleResize = (e) => {
        if (e.matches) {
          setHideTileControl(true);
        } else {
          setHideTileControl(false);
        }
      };
  
      mql.addEventListener('change', handleResize);
  
      return () => {
        mql.removeEventListener('change', handleResize);
      };
    }, []);
  const handleEvent = (e) => {
    if (e.target.className === 'phillips-lot' && !props.isNoLot) {
      if (e.type === 'keydown' && e.key !== 'Enter') {
        return false;
      }
      e.preventDefault();
      // eslint-disable-next-line no-restricted-globals
      location.assign(props.detailLink);
    }
    return true;
  };
  const handleIsVisibleChange = (visible) => {
    if (visible !== isVisible && !isVisible) {
      setIsVisible(visible);
    }
  };

  const handleHover = (e) => {
    if(!hideTileControl) return
    if (e.type === 'mouseenter') {
      setIsHovering(true);
    } else if (e.type === 'mouseleave'){
      setIsHovering(false);
    }
  };
  
    useEffect(() => {
        if (isVisible && !isNoLot) {
            // disableFetchRowId is a boolean to disable dispatch(fetchLotWidgetData(amLotRowId)) on isVisible 
            // this is used in the marketplace carousel and will have to be disabled when the lots don't need to individually fetch widget data
            if (showBidButton && !disableFetchRowId) {
                if (auctionMobilityLotRowId !== '0' && fetchLotRowIds.indexOf(auctionMobilityLotRowId) === -1) {
                    if (fetchedLotRowIds.indexOf(auctionMobilityLotRowId) === -1) {
                        fetchLotWidgetData(auctionMobilityLotRowId);
                    }
                }
            }
        }
    }, [isVisible, auctionMobilityLotRowId]);
  const lot = {
    lotNumber: props.lotNumber,
    saleNumber: props.saleNumber,
    lotNumberSuffix: props.lotNumberSuffix,
    lotNumberFull: props.lotNumberFull
  };
  return (
    <VisibilitySensor
      delayedCall
      // partialVisibility
      intervalDelay={1000}
      onChange={handleIsVisibleChange}
      scrollCheck
      scrollDelay={500}
    >
      {isNoLot
        ? <NoLot handleEvent={handleEvent} {...props} showBidButton={showBidButton} />
        : (
          <div
            className={classNames(
              'phillips-lot',
              className,
              {
                'phillips-lot--widget-enabled': showBidButton,
                'phillips-lot--buy-now': saleTypeId === 5
              }
            )}
            onKeyDown={handleEvent}
            onClick={handleEvent}
            role="button"
            tabIndex={0}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
          >
            <PhillipsLotImage {...props} isVisible={isVisible} loadType={isVisible ? 'eager' : 'lazy'} />
            <div className={'phillips-lot__content'}>
              <LotDescription {...props} showLotNumber={saleTypeId !== 5 && saleTypeId !== 6} getI18nLabel={getI18nLabel} isVisible={isVisible} showBidButton={showBidButton} websiteUrl={websiteUrl} />
              <div className={classNames('phillips-lot__user-actions row', { 'lot-sold': (hammerPlusBP > 0) })} onClick={ () => hideTileControl && setIsHovering(false)}>
                {props.editable || props.hideUserActions
                  ? null
                  : <div onClick={() => {
                  sendAnalytics({
                    eventCategory: `Favorite Lot`,
                    eventAction: 'Favorite Lot',
                    eventLabel: `Favorite Lot`,
                    userEmail: user?.email
                  });
                }}>
                <LazyPhillipsPublicLotTileControl
                    phillipsPublicLot={props}
                    hideControl={hideTileControl && !isHovering}
                  />
                  </div>
              }
              </div>
            </div>
          </div>
        )
      }
    </VisibilitySensor>
  );
};

PhillipsLot.defaultProps = defaultLotProps;

PhillipsLot.propTypes = lotPropTypes;

export default PhillipsTranslations(PhillipsLot);