/* eslint-disable no-confusing-arrow */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import BidButton from '../BidButtons/BidButton';
import Widget from '../BidButtons/Widget';
import { lotPropTypes } from '../PropTypes/proptypes';
import formatEstimate from '../utils/formatEstimate';
import setLineBreaks from '../utils/setLineBreaks';
import lotImgSrc from './lotImgSrc'

const LotEstimate = props => {
  let estimate = null;
  const { getI18nLabel } = props;
  if (props.saleTypeId === 5 && props.buyNowPrice > 1) {
    return (
      <p className="phillips-lot__description__estimate phillips-lot__description__estimate--buy-now">
        <strong className="phillips-lot__description__estimate__strong">{getI18nLabel({ label: 'price' })}</strong>
        &nbsp;
        {props.currencySign}
        {formatEstimate(props.buyNowPrice)}
        &nbsp;
        {props.estimateSpecialChar}
        <br />
      </p>
    );
  }
  if (props.estimateText !== null && props.estimateText.length > 0) {
    // if there is estimateText
    return (
      <p className="phillips-lot__description__estimate">
        <span>
          {camelCase(props.estimateText) === 'priceUponRequest'
            ? getI18nLabel({ label: camelCase(props.estimateText) })
            : props.estimateText}
        </span>
      </p>
    );
  }
  if (props.lowEstimate) {
    // if there is a lowEstimate
    if (props.saleTypeId === 4) {
      if (props.showSaleOffers) {
        return (
          <p className="phillips-lot__description__estimate">
            <span>{getI18nLabel({ label: 'askingPrice' })}</span>
            &nbsp;
            {props.currencySign}
            {formatEstimate(props.lowEstimate)}
            &nbsp;
            {props.estimateSpecialChar}
            <br />
          </p>
        );
      }
    }
    return (
      <p className="phillips-lot__description__estimate">
        <span>{getI18nLabel({ label: 'estimate' })}</span>
        &nbsp;
        <span className="phillips-lot__description__estimate__price">
          <span>
            {props.currencySign}
            {formatEstimate(props.lowEstimate)}
          </span>
          &nbsp;-&nbsp;
          <span>{formatEstimate(props.highEstimate)}</span>
          {isEmpty(props.estimateSpecialChar) ? null : (
            <React.Fragment>
              &nbsp;
              {props.estimateSpecialChar}
            </React.Fragment>
          )}
        </span>
        <br />
      </p>
    );
  }
  if (!props.isExhibition) {
    if (props.saleTypeId === 5) {
      estimate = (
        <p className="phillips-lot__description__estimate--buy-now">
          <span className="phillips-lot__description__estimate__strong">{getI18nLabel({ label: 'inquire' })}</span>
        </p>
      );
    }
    estimate = <p>{getI18nLabel({ label: 'estimateOnRequest' })}</p>;
  }
  return estimate;
};

const showHammer = props => {
  const { getI18nLabel } = props;
  if (props.saleTypeId === 4) {
    return props.showSoldPrice ? <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p> : null;
  }
  if (props.hammerPlusBP > 0) {
    if (props.saleTypeId === 5 && props.showEstimateText && !props.showSoldPrice && !props.isSoldOverride) {
      return;
    }
    if (!props.showSoldPrice) {
      return <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p>;
    }
    // fix for WEB-4667 [PERPETUAL] - Change sold price text logic to be based on sale number EX080519

    // if (props.saleTypeId === 5 && !props.showSoldPrice) {
    if (props.saleTypeId === 5 && props.saleNumber !== 'EX011621') {
      return <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p>;
    }
    return (
      <p className="phillips-lot__sold">
        {getI18nLabel({ label: 'soldFor' })}
        &nbsp;
        {props.currencySign}
        {formatEstimate(props.hammerPlusBP)}
      </p>
    );
  }

  if (props.isSoldOverride && (!props.hammerPlusBP || props.hammerPlusBP <= 0)) {
    return <p className="phillips-lot__sold">{getI18nLabel({ label: 'sold' })}</p>;
  }
  return null;
};

const toggleEstimateHammer = props =>
  props.hammerPlusBP > 0 ? showHammer(props) : props.showEstimateText ? <LotEstimate {...props} /> : null;

const LotDescription = props => {
  const imgsrc = lotImgSrc(props)

  return (
    <a
      className={classNames('phillips-lot__description', {
        'phillips-lot__description--is-watch': props.isWatch || props.isMixedAuction,
        'phillips-lot__description--buy-now': props.saleTypeId === 5,
        'phillips-lot__description--has-hammer': props.hammerPlusBP > 0 || props.saleTypeId === 4
      })}
      href={props.detailLink}
    >
      <p className={classNames('phillips-lot__description__lot-number-wrapper')}>
        {props.showLotNumber || props.isNoReserve ? (
          <span className="phillips-lot__description__lot-number-wrapper__text-wrapper">
            {props.showLotNumber ? (
              <strong className="phillips-lot__description__lot-number-wrapper__lot-number">
                {props.lotNumberFull.trim()}
              </strong>
            ) : null}
            {props.isNoReserve && (props.timeState !== 3 || !props.endSale) ? (
              <strong className="phillips-lot__description__lot-number-wrapper__no-reserve">
                {props.getI18nLabel({ label: 'noReserve' })}
              </strong>
            ) : null}


          </span>
        ) : null}
        {imgsrc ? (
          <span className="phillips-lot__description__ultimate-text">
            <img src={imgsrc} alt="ultimate label text" />
          </span>
        ) : null}
        {(props.videoSource && props.videoSource.length > 0) || props.is360View ? (
          <span className="phillips-lot__description__lot-number-wrapper__img-wrapper">
            {props.is360View ? (
              <img
                className="phillips-lot__has-360"
                height={34}
                src={`${GLOBAL_CDN_URL}images/icons/360icons/360icon.png`}
                alt="360 View"
                title="360 View"
                width={50}
              />
            ) : null}
            {props.videoSource && props.videoSource.length > 0 ? (
              <img
                alt="Video Link"
                className="phillips-lot__has-video"
                height={17}
                src={`${GLOBAL_CDN_URL}images/icons/video-icon.svg`}
                title="Video Link"
                width={17}
              />
            ) : null}
          </span>
        ) : null}
      </p>
      {props.makerId ? (
        <p
          className={classNames('phillips-lot__description__artist', {
            'phillips-lot__description__artist--buy-now': props.saleTypeId === 5
          })}
          title={`${props.getTranslatedString('makerName')}`}
        >
          {/* shows artist name in Chinese when ZH */}
          {props.getTranslatedString('makerName')}
        </p>
      ) : null}
      <p
        className={classNames('phillips-lot__description__title', {
          'phillips-lot__description__title--no-makerName': !props.makerId
        })}
        title={`${props.getTranslatedString('description')}`}
      >
        {props.wReferenceNo && props.wReferenceNo.length > 0 ? (
          <span
            className={classNames({
              'phillips-lot__description__ref-num--buy-now': props.saleTypeId === 5
            })}
          >
            {props.getI18nLabel({
              label: 'referenceNumberShort',
              args: [props.wReferenceNo]
            })}
          </span>
        ) : null}
        {props.wReferenceNo && props.wReferenceNo.length > 0 ? <br /> : null}
        {props.wModelName && props.wModelName.length > 0 ? (
          <span>
            Model:&nbsp;
            {props.wModelName}
            &nbsp;
          </span>
        ) : null}
        {props.wModelName && props.wModelName.length > 0 ? <br /> : null}
        {props.getTranslatedString('descriptionWithMarkup') &&
          props.getTranslatedString('descriptionWithMarkup').length > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: setLineBreaks(props.getTranslatedString('descriptionWithMarkup'))
            }}
          />
        ) : (
          <em>{props.getTranslatedString('description')}</em>
        )}
      </p>
      {props.toggleEstHammer ? toggleEstimateHammer(props) : props.showEstimateText ? <LotEstimate {...props} /> : null}
      {props.toggleEstHammer ? null : showHammer(props)}
      {props.showSaleTitle ? (
        <p className="phillips-lot__sale-info">{`Lot ${props.lotNumber} \u{2022} ${props.saleTitle}`}</p>
      ) : null}
      {props.showObjectNumber ? <p className="phillips-lot__sale-info__object-number">{props.objectNumber}</p> : null}
      {props.isNoLot || !props.showBidButton || props.isSoldOverride ? null : props.saleTypeId === 6 ? (
        <Widget {...props} auctionMobilityLotRowIds={props.auctionMobilityLotRowIds} layout="grid" />
      ) : (
        <BidButton
          auctionMobilityLotRowIds={props.auctionMobilityLotRowIds}
          layout="grid"
          lot={{
            ...props,
            saleTypeId: 3,
            auctionBidPartner: 1
          }}

        />
      )}
    </a>
  );
};

LotDescription.defaultProps = {
  auctionMobilityLotRowIds: [],
  isWatch: false,
  isNoLot: false,
  showSaleTitle: false,
  showBidButton: false
};

LotDescription.propTypes = {
  ...lotPropTypes,
  auctionMobilityLotRowIds: PropTypes.arrayOf(PropTypes.string),
  getTranslatedString: PropTypes.func.isRequired,
  isNoLot: PropTypes.bool,
  isWatch: PropTypes.bool,
  saleTypeId: PropTypes.number.isRequired,
  showBidButton: PropTypes.bool,
  showSaleTitle: PropTypes.bool,
  toggleEstHammer: PropTypes.bool.isRequired
};

export default LotDescription;
