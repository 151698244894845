import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sendAnalytics from '../../utils/sendAnalytics';
import breakpoints from '../utils/breakpoints';

export default class DropshopBanner extends Component {
  constructor(props) {
    super(props);
    this.submitConsignAnalytics = this.submitConsignAnalytics.bind(this);
    this.state = {
      hidden: true,
      showBanner: this.props.backgroundUrl
    };
  }

  componentDidMount() {
    breakpoints(mql => {
      let backgroundUrl = '';
     if(mql.size ==='xs' || mql.size ==='sm'){
        backgroundUrl=`${GLOBAL_CDN_URL}consignment-banners/Dropshop-Banner-mobile.jpg`;
      }
      else if(mql.size ==='md'){
        backgroundUrl = `${GLOBAL_CDN_URL}consignment-banners/Dropshop-Banner-medium.jpg`;
      }
      else{
        backgroundUrl = `${GLOBAL_CDN_URL}consignment-banners/Dropshop-Banner-desktop.jpg`;
      }
      return this.setState({
        showBanner: backgroundUrl
      });
    });
  }

  submitConsignAnalytics() {
    sendAnalytics({
      eventCategory: 'Consignment Click',
      eventAction: `Clicked Consignment Promotion, Homepage Banner: ${this.props.bannerId}`,
      eventLabel: 'www.phillips.com'
    });
  }

  render() {
    const imageStyle = {
      backgroundImage: `url('${this.state.showBanner}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: this.props.bannerHeight,
      width: '100%',
      marginLeft: 0,
      marginBottom: '60px',
      position: 'relative',
      zIndex: 0
    };
    const linkStyle = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: '#ffffff',
      //   msFilter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
      //   filter: `${alpha((opacity = 0))}`,
      opacity: 0
    };


    return (
      <div className="sell-consignment-g1 consignment-home-g1" style={imageStyle}>
        <a style={linkStyle} href={this.props.href} onClick={() => this.submitConsignAnalytics()} />
        <div className="container dropshop-container">{this.props.children}</div>
      </div>
    );
  }
}

DropshopBanner.defaultProps = {
  backgroundColor: '#eee',
  backgroundUrl: '',
  bannerId: 'Bottom',
  bgFit: 'inside',
  bgHorizontalJustify: 'left',
  bgVerticalJustify: 'center',
  consignmentText: 'We are inviting consignments for our upcoming auctions.',
  copyJustify: 'center',
  bannerHeight: 365,
  href: '/sell',
  sellText: 'Sell with us.',
  submitText: ''
};

DropshopBanner.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundUrl: PropTypes.string,
  bannerHeight: PropTypes.number,
  bannerId: PropTypes.string,
  bgFit: PropTypes.string, // inside, breaking
  bgHorizontalJustify: PropTypes.string, // left, right
  bgVerticalJustify: PropTypes.string, // top, center, bottom
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  consignmentText: PropTypes.string,
  copyJustify: PropTypes.string, // center, offset
  href: PropTypes.string,
  sellText: PropTypes.string,
  submitText: PropTypes.string
};
